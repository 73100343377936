<template>
  <div class="w-100" :class="{ fixed: video }">
    <div id="webcam" style="position:relative; display: none;">
      <div class="countdown"></div>
      <video id="player" class="webcamVideo" autoplay playsinline width="100%"></video>
      <canvas style="display: none" id="canvas" width="455" height="607"></canvas>
    </div>
    <transition
      enterActiveClass="animate__animated animate__fadeIn"
      leaveActiveClass="animate__animated animate__fadeOut"
    >
      <reaction-stripe
        v-show="( showVideo360 || showVideo ) && rbv || (( showVideo360 || showVideo ) && writing) "
        :player="rdsPlayer"
        :player-instance="rdsPlayer"
        :player-state="true"
        :customFS="true"
        :isVisible="( showVideo360 || showVideo ) && rbv || (( showVideo360 || showVideo ) && writing)"
        @activity="writingDelay()"
      />
    </transition>
    <template v-if="video">
      <Player360
        @tapped="writingDelay()"
        v-if="showVideo360"
        :url="video.stream_hls"
        :id="video.video_id"
        :token="get(this.loginInfo, 'token')"
        @exit="exit()"
        :options="[]"
        :showOptions="( showVideo360 || showVideo ) && rbv"
        @handleVideo="handleVideo"
        :type="get(video, 'video_type')"
        :leftFix="true"
      />
      <Player
        @tapped="writingDelay()"
        v-if="showVideo"
        :url="video.stream_hls"
        :id="video.video_id"
        :token="get(this.loginInfo, 'token')"
        @exit="exit()"
        :options="[]"
        :showOptions="( showVideo360 || showVideo ) && rbv"
        @handleVideo="handleVideo"
        :type="get(video, 'video_type')"
      />
    </template>
    <div class="Videos" v-else>
      <div v-for="video in get(category, 'videos' ,[])" class="VideoCell" :key="video.video_id">
        <div
          class="VideoCell__cover"
          :style="{ backgroundImage: `url(${video.cover.replace('{$width}', '576')})`}"
          @click="handleVideo(video)"
        >
          <play-btn />
        </div>
        <div class="VideoCell__title">
          {{ video.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { intersection, get, isFunction } from 'lodash';
import { mapState, mapMutations } from 'vuex';
import Player from '@/components/Player.vue';
import Player360 from '@/components/Player360.vue';
import ReactionStripe from '@/components/ReactionStripeRDS.vue';
import PlayBtn from './glyphs/PlayBtn.vue';

export default {
  name: 'EniVideos',
  components: {
    PlayBtn,
    Player,
    Player360,
    ReactionStripe,
  },
  data() {
    return {
      get,
      video: false,
      showVideo360: false,
      showVideo: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
    };
  },
  props: ['category', 'register'],
  methods: {
    ...mapMutations(['loadRedeem', 'openRedeem']),
    exit() {
      this.video = false;
      this.showVideo = false;
      this.showVideo360 = false;
    },
    writingDelay() {
      clearTimeout(this.writingTo);
      this.writing = true;
      this.writingTo = setTimeout(() => {
        this.writing = false;
      }, 5000);
    },
    loadVideo(id) {
      const that = this;
      const ep = `/video/${id}`;
      this.api.get(ep, {
        headers: {
          'x-tok-token': get(this.loginInfo, 'token'),
          'Accept-Language': this.locale,
        },
      })
        .then((response) => {
          that.video = response.data;
          if (that.video.video_360 || that.video.title.includes('360')) {
            that.showVideo360 = true;
            that.showVideo = false;
          } else {
            that.showVideo360 = false;
            that.showVideo = true;
          }
          this.startReactionStripe();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    startReactionStripe() {
      this.interval = setInterval(() => {
        const isF = isFunction(this.rdsPlayer.userActive);
        if (isF) {
          this.rbv = this.rdsPlayer.userActive();
        }
      }, 250);
      /*
      const env = 'special';
      const reactionStripeConfig = {
        ...this.reactionStripeConfig,
        ADS_URL: this.conf[env].ADS_URL,
        appid: this.conf[env].appid,
        service: this.conf[env].service,
        host: this.conf[env].host,
        BASE_GROUP_PHOTO_URL: this.conf[env].BASE_GROUP_PHOTO_URL,
        BASE_SHARE_URL: `${this.conf[env].BASE_SHARE_URL + this.conf[env].appid}/${this.$t('lang')}?id=`,
        TWITTER_TEXT: encodeURI(this.$t('TWITTER_TEXT')),
      };
      */
      window.start(get(this.loginInfo, 'token'), this.reactionStripeConfig);
      window.initBar();
    },
    // eslint-disable-next-line consistent-return
    handleVideo(video) {
      if (!get(this, 'me.registered')) {
        this.register();
      } else {
        const activeOffers = get(this, 'me.active_offers', []);
        const validArray = intersection(activeOffers, video.offers);
        if (validArray.length > 0) {
          this.loadVideo(video.video_id);
        } else {
          const ro = {
            offers: [...video.offers],
            play: false,
            route: false,
          };
          this.loadRedeem(ro);
        }
        console.log(video);
      }
    },
  },
  computed: {
    ...mapState(['me', 'api', 'loginInfo', 'locale', 'reactionStripeConfig', 'rdsPlayer']),
  },
};
</script>

<style lang="scss" scoped>
.Videos {
  display: flex;
  padding: 20px;
  justify-content: center;
  width: 100%;
  @media only screen and (max-width: 600px) {
    flex-wrap: wrap;
    padding: 0;
    margin-top: 20px;
  }
}

.VideoCell {
  margin: 20px;
  flex: 0 1 33%;
  @media only screen and (max-width: 600px) {
    flex: 0 1 100%;
    margin: 0;
  }

  &__cover {
    padding-top: 56.25%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-position: center center;
    background-size: cover;
  }

  &__title {
    margin-top: 15px;
    color: white;
    opacity: 0;
  }
}

.playBtn {
  position: absolute;
  top: 0;
}
.fixed {
  position: fixed;
  top: 0;
  left: 0;
}
</style>
